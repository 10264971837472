import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpEventType} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {HttpOptionsService} from '../services/http-options.service';
import {TransformParams} from '../interfaces/transform-params.interface';
import {BaseModel, BaseModelItems} from '../classes/base.model';
import {Observable} from 'rxjs';

@Injectable()
export class TransformerInterceptor implements HttpInterceptor {
    private params: TransformParams;

    constructor(
        private httpOptions: HttpOptionsService,
    ) {}


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<BaseModel | BaseModelItems<BaseModel> | BaseModel[]>> {
        const params: TransformParams = this.getParams();
        return next.handle(request)
            .pipe(
                map((res: HttpResponse<any> | {type: number}) => {
                    if(request.responseType == 'json') {
                        return this.transform(res, params)
                    }

                    return res;
                }),
            )
    }

    private getParams(): TransformParams {
        this.params = {
            model: this.httpOptions.model,
            isListing: this.httpOptions.isListing,
        };
        
        return this.params;
    }

    private transform(res: HttpResponse<any> | {type: number}, params: TransformParams): HttpEvent<BaseModel | BaseModelItems<BaseModel> | BaseModel[]> {
        if (!params.model || !(res instanceof HttpResponse) || !res.body) {
            return res;
        }

        if (!params.isListing) {
            return this.transformItem(res, params);
        }
        
        return this.transformListing(res, params);
    }

    private transformItem(res: HttpResponse<any>, params: TransformParams) {
        return res.clone<BaseModel>({
            body: new params.model(res.body)
        });
    }

    private transformListing(res: HttpResponse<any>, params: TransformParams) {
        if(res.body.items) {
            res.body.items = params.model.transformCollection(res.body.items);
            return res;
        }

        return res.clone<BaseModel[]>({
            body: params.model.transformCollection(res.body)
        });
    }
}
