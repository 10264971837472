import {HttpService} from '../services';
import {HttpExtendedParams, Options} from '../interfaces';
import {Observable} from 'rxjs';
import {HttpServiceContract} from '../interfaces/http-service-contract';
import {tap} from "rxjs/operators";

export class HttpClient {
    private http: HttpService;
    constructor(
        private service: HttpServiceContract
    ) {
        this.http = this.service.http
    }
    
    public get(url: string, options: Options = {}, params?: any): Observable<any> {
        return this.http.get(url, this.prepareOptions(options), params);
    }

    public blob(url: string, params: any = {}, options: any = {}): Observable<any> {
        return this.get(
            url, Object.assign({}, options, {responseType: 'blob'})
        )
    }
    
    public post(url: string, options: Options = {}, params?: HttpExtendedParams | any): Observable<any> {
        return this.http.post(url, this.prepareOptions(options), params);
    }
    
    public put(url: string, options: Options = {}, params?: any): Observable<any> {
        return this.http.put(url, this.prepareOptions(options), params);
    
    }
    
    public delete(url: string, options: Options = {}, params?: any): Observable<any> {
        return this.http.delete(url, this.prepareOptions(options), params);
    }
    
    private prepareOptions(options: Options): Options {
        return Object.assign({}, this.service.httpOptions, options);
    }
    
}
