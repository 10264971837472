import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CacheInterceptor} from './interceptors';
import {CacheOptionService} from './services';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpOptionSharerService} from './services';
import {CacheService} from './services';
import {StoreService} from './services';
import {Configuration} from './constants';
import {ConfigsContract} from './interfaces';
import {getPreparedConfigs} from './helpers';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
})
export class HttpCacheModule {
  static forRoot(config: ConfigsContract = {}) {
    return {
      ngModule: HttpCacheModule,
      providers: [
        CacheService,
        CacheOptionService,
        StoreService,
        {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
        {provide: 'HttpOptionSharers', useClass: HttpOptionSharerService, multi: true},
        {provide: 'HttpOptionSharers', useClass: HttpOptionSharerService, multi: true},
        {provide: Configuration, useValue: config},
      ]
    }
  }
}
