import {CacheParamsContract} from '../interfaces';

export class CacheParams implements CacheParamsContract {
    shouldCache: boolean;
    cacheKey: string;
    removingCacheKey: string;
    expireTime: number;
    
    constructor(data) {
        this.shouldCache = !!data.cacheKey;
        this.cacheKey = data.cacheKey;
        this.removingCacheKey = data.removingCacheKey;
        this.expireTime = data.expireTime;
    }
    
    public getFullCacheKey(url: string): string[] {
        return [this.cacheKey, url];
    }
}
