export const USD_TO_AMD_RATE = 481.86;


export const fromAMDToUSD = (amount: number) => {
    return amount / USD_TO_AMD_RATE;
};

export const fromUSDToAMD = (amount: number) => {
    return amount * USD_TO_AMD_RATE;
};
