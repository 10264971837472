import {StorageContract} from '../../interfaces';
import {LocalStorage} from './local.storage';
import {SessionStorage} from './session.storage';
import {MemoryStorage} from './memory.storage';
import {StorageTypes} from '../../constants';
import {ConfigsContract} from '../../interfaces';

export class StorageFactory {
    public static getStorageByType(configs: ConfigsContract): StorageContract {
        switch (configs.type) {
            case StorageTypes.localStorage: return new LocalStorage(configs);
            case StorageTypes.sessionStorage: return new SessionStorage(configs);
            default: return new MemoryStorage(configs);
        }
    }
}
