import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard, GuestGuard} from '../main/modules/auth/guards';


const appRoutes: Routes = [
	{
		path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
		canActivate: [ AuthGuard ]
	},
    {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule',
        canActivate: [ GuestGuard ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes),
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})

export class AppRoutingModule { }
