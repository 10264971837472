export class BaseModel {

    constructor(data: any = {}) {}

    public static transform<T extends BaseModel>(data: any) : T {
        if(data == null) {
            return null;
        }
        return <T>(new this(data));
    }

    public static transformCollection<T extends BaseModel>(data: Array<any>) : Array<T> {
        let transformedData = [];
        for(let item of data) {
            transformedData.push(this.transform(item));
        }
        return <T[]>transformedData;
    }
}

export interface BaseModelItems<T> {
    count: number;
    total: number,
    hasMorePages: boolean;
    items: T[],
}
