import {Inject, Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../services';
import {catchError, map, tap} from 'rxjs/operators';
import {Configuration} from '../../http-cache/constants';
import {ConfigsContract} from '../interfaces/configs.interface';
import {BaseAuthGuard} from './base-auth.guard';

@Injectable()
export class AuthGuard extends BaseAuthGuard {
  
    protected checkAuthFail(): Observable<boolean> {
        this.router.navigate([this.configuration.loginUrl]);
        return of(false);
    }
    
    protected checkSuccessResponse(): boolean {
        return true;
    }
}
