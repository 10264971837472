import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {HandledError} from '../classes/handled-error';
import {HandlerService} from './handler.service';
import {HttpOptionsService} from './http-options.service';

@Injectable()
export class ErrorHandlerService extends HandlerService {
    public validStatusCodes: number[] = [404, 403, 401, 402, 422, 415, 491];
    public handle(error: HttpErrorResponse | Error): Observable<HandledError> {
        let res: HandledError;

        if(error instanceof HttpErrorResponse) {
            res = this.handleHttpError(error);
        } else {
            res = this.handleTypeError(error);
        }

        if(!this.httpOptionsService.dontHandleError) {
            this.addNotification(res.toString(), 'error');
        }
        
        return throwError(res);
    }

    protected handleHttpError (response: HttpErrorResponse) : HandledError {
        let err: HandledError = new HandledError(response.status);

        let errorMessages = Object.assign(this.notificationMessages.messages, this.httpOptionsService.errorMessages);
        
        if(errorMessages[response.status]) {
            err.text = errorMessages[response.status];
            return err;
        }
        
        if(response.error.errorMessage){
            if( this.validStatusCodes.indexOf(response.status) != -1) {
                err.text = response.error.errorMessage
            }
        } else {
            for (let key in response.error) {
                err.text = response.error[key][0];
            }
        }
        
        return err;
    }

    protected handleTypeError (error: Error) : HandledError {
        console.error(error);
        return new HandledError();
    }
}
