import { Injectable } from '@angular/core';
import {HttpOptionSharerContract} from '../interfaces/http-option-sharer.interface';
import {Options} from '../interfaces';

@Injectable()
export class HttpOptionSharerService implements HttpOptionSharerContract{
  private options: Options;
  
  constructor() { }
  
  setOptions(data: any): void {
    this.options = data;
  }
}
