import {Inject, Injectable} from '@angular/core';
import {HttpOptions} from '../interfaces/http-options.interface';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {HttpSimpleParams} from '../interfaces/http-simple-params.interface';
import {Options} from '../interfaces/options.interface';
import {HttpOptionSharerContract} from '../interfaces/http-option-sharer.interface';

@Injectable()
export class HttpOptionsService {
    private options: HttpOptions = {
        headers: null,
        observe: null,
        params: null,
        reportProgress: null,
        responseType: 'json',
        withCredentials: null
    };

    public dontHandleError: boolean;
    public model: any;
    public isListing: boolean;
    public handleSuccessResponse: boolean;
    public errorMessages: {
        [key: number]: string
    };

    constructor(
        @Inject('HttpOptionSharers') private readonly optionsSharers: HttpOptionSharerContract[],
    ) {
        this.options.headers = new HttpHeaders({
            'Accept': 'application/json'
        });
    }
    
    public getOptions(params?: HttpSimpleParams, options: Options = {}): HttpOptions {
        this.mergeOptions(options);
        
        this.setParams(params);
      
        return this.options;
    }
    
    public setOptions(options: HttpOptions): void {
        this.options = options;
    }
    
    private setHeader(name: string, value: string): void {
        this.options.headers = this.options.headers.set(name, value);
    }

    public setAuth(token: string): void {
        this.setHeader('Authorization', token);
    }

    private initDefaultOptions(): void {
        this.options.responseType = 'json';
    }

    private mergeOptions(options: Options): void {
        this.initDefaultOptions();
        for(let i in options) {
            if(this.options.hasOwnProperty(i)) {
                this.options[i] = options[i];
            }
        }
        this.dontHandleError = options.dontHandleError;
        this.handleSuccessResponse = options.handleSuccessResponse;
        this.model = options.model;
        this.isListing = options.isListing;
        this.shareOptions(options);
        this.errorMessages = options.errorMessages
    }
    
    private shareOptions(options: Options): void {
        this.optionsSharers.forEach(sharer => {
            sharer.setOptions(options);
        })
    }
    
    private setParams(params: HttpSimpleParams): void {
        this.options.params = params || {};
    }
    
    public removeAuth(): void {
        this.options.headers.delete('Authorization');
    }
}
