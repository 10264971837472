import { Component } from '@angular/core';
import {BaseComponent} from '../main/base.component';
import {MetadataService} from "./services/metadata.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {

    constructor(
        private metadataService: MetadataService
    ) {
        super();
        this.setDatabase();
    }

    private setDatabase(): void {
        this.metadataService.initDB();
        this.metadataService.updateTableRow('projects', 1, {name: `Kizen`});
        this.metadataService.updateTableRow('projects', 2, {name: `QTX`});

    }

}
