import {Inject, Injectable} from '@angular/core';
import {HttpOptionsService, HttpService} from '../../http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {User} from '../../../models/user';
import {UserLogin} from '../../../interfaces/user-login';
import {HttpClient} from '../../http';
import {HttpServiceContract} from '../../http/interfaces/http-service-contract';
import {CacheOptionService} from '../../http-cache/services';
import {CacheKeys} from '../../../constants/cache.constant';

@Injectable()
export class AuthService implements HttpServiceContract {
    private user: User;
    private readonly storageTokenKey: string = 'token';
    public loggedInState = new BehaviorSubject<boolean>(undefined);
    public httpOptions = {
        model: User,
        errorMessages: {
            401: 'Unauthorized test'
        }
    };
    
    private httpClient: HttpClient;
    
    constructor(
        public http: HttpService,
        private httpOptionsService: HttpOptionsService,
        private cacheOptionService: CacheOptionService,
    ) {
        this.httpClient = new HttpClient(this);
        this.setAuth(this.getTokenFromStorage());
    }
    
    public login(data: UserLogin): Observable<User> {
        return this.httpClient.post(`login`, {removingCacheKey: CacheKeys.userList}, data)
            .pipe(
                tap((user: User) => this.setUser(user))
            )
    }
    
    private setAuth(token: string): void {
        this.httpOptionsService.setAuth('Bearer ' + token);
    }
    
    private loginStateFromSubject(): Observable<User> {
    
        if(this.loggedInState.getValue()) {
            return of(this.user);
        }
    
        return throwError(this.user);
    }
    
    public checkLoginState(): Observable<User> {
        if(this.loggedInState.getValue() !== undefined) {
            return this.loginStateFromSubject();
        }
        
        return this.checkUser();
    }
    
    public checkUser(): Observable<User> {
        // this.cacheOptionService.setCacheParams({cacheKey: CacheKeys.userList});
        return this.httpClient.get(`me`)
            .pipe(
                tap((user: User) => this.setUser(user, false)),
                catchError(_ => {this.loggedInState.next(false); return throwError(false)})
            )
    }
    
    private setUser(user: User, setAuth: boolean = true): void {
        this.user = user;

        if (setAuth) {
            this.setAuth(user.apiToken);
            this.saveInStorage(user.apiToken)
        }
        
        this.setLoginState(true);
    }
    
    public logout(): Observable<any> {
        return this.httpClient.post(`logout`)
            .pipe(
                tap(_ => this.removeAuth())
            )
    }
    
    private saveInStorage(token: string): void {
        localStorage.setItem(this.storageTokenKey, token);
    }
    
    private getTokenFromStorage(): string {
        return localStorage.getItem(this.storageTokenKey);
    }
    
    private removeFromStorage(): void {
        localStorage.removeItem(this.storageTokenKey);
    }
    
    private removeAuth(): void {
        this.removeFromStorage();
        
        this.setLoginState(false);
        this.httpOptionsService.removeAuth();
    }
    
    public setLoginState(value: boolean): void {
        this.loggedInState.next(value);
    }
    
    public getUser(): User {
        return this.user;
    }
}
