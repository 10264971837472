import {StorageContract} from '../../interfaces';
import {BaseStorage} from './base.storage';
import {ConfigsContract} from '../../interfaces';

export class SessionStorage extends BaseStorage implements StorageContract{
    
    constructor(configs: ConfigsContract) {
        super(configs);
        this.checkStorageData();
    }
    
    public set(key: string[], data: any): void {
        super.set(key, data);
        this.pushStorageData();
    }
    
    public remove(key: string[]): void {
        super.remove(key);
        this.pushStorageData();
    }
    
    private checkStorageData(): void{
        if(!this.pullStorageData()) {
            this.pushStorageData();
        }
    }
    
    private pushStorageData(): void {
        if(!this.storeData) {
            this.storeData = {};
        }
        
        sessionStorage.setItem(this.configs.storageKey, JSON.stringify(this.storeData));
    }
    
    private pullStorageData(): boolean {
        try {
            this.storeData = JSON.parse(sessionStorage.getItem(this.configs.storageKey));
            
        } catch (e) {
            this.storeData = {};
            this.pushStorageData();
            
            return false;
        }
        
        return !!this.storeData;
    }
}
