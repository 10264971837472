import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {CacheOptionService} from '../services';
import {CacheService} from '../services';


@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(
        private cachingService: CacheService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let response = this.cachingService.handleCacheProcess(req, next);
        
        this.cachingService.resetCacheParams();
        
        return response;
        
    }
}
