import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MetadataService {
    DATABASE_NAME = `db`;
    private database: any;

    constructor() {}

    public initDB(): void {
        const currentDB = this.getDatabase();
        if (currentDB) {
            this.database = currentDB;
            return;
        }
        const data = {
            projects: {
                data: []
            },
            users: {
                data: []
            }
        };
        this.setDatabase(data);
    }

    getTable(tableName: string): any {
        const tableData = this.database[tableName];
        if (!tableData) {
            return
        }
        return tableData;
    }

    public selectRowWithKey(tableName: string, value: any, key = 'id'): any {
        const tableData = this.getTable(tableName);
        return tableData.data.find(item => item[key] === value);
    }

    public selectRowsWithKey(tableName: string, value: any, key = 'id'): any {
        const tableData = this.getTable(tableName);
        return tableData.data.filter(item => item[key] === value);
    }

    public addRowToTable(tableName: string, data: any): void {
        const tableData = this.getTable(tableName);
        if (this.checkDataExists(tableName, data.id)) {
            console.error(`id exists`);
            return;
        }
        tableData.data.push(data);
        this.setDatabase(this.database);
    }

    public updateTableRow(tableName: string, id: number, data: any): void {
        const tableData = this.getTable(tableName);
        for (let i = 0; i < tableData.data.length; i++) {
            const row = tableData.data[i];
            if (row.id === id) {
                for (const key in data) {
                    if (row.hasOwnProperty(key)) {
                        row[key] = data[key];
                    }
                }
                break;
            }
        }
        this.database[tableName] = tableData;
        this.setDatabase(this.database);
    }

    public deleteRowFromTable(tableName: string, id: number): void {
        const tableData = this.getTable(tableName);
        tableData.data = tableData.data.filter(item => item.id !== id);
        this.database[tableName] = tableData;
        this.setDatabase(this.database);
    }

    private checkDataExists(tableName: string, id: number): boolean {
        const tableData = this.getTable(tableName);
        return !!tableData.data.find(item => item.id === id);
    }

    private getStorageItem(key: string): any {
        const data = localStorage.getItem(key);
        return JSON.parse(data);
    }

    private getDatabase(): any {
        return this.getStorageItem(this.DATABASE_NAME);
    }

    private setDatabase(data: any): void {
        this.database = data;
        localStorage.setItem(this.DATABASE_NAME, JSON.stringify(data));
    }

}
