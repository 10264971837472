import {Inject, Injectable} from '@angular/core';
import {HttpOptionsService} from './http-options.service';
import {NotificationHandlerService} from './notification-handler.service';
import {NotificationMessagesService} from './notification-messages.service';

@Injectable()
export class HandlerService {
    constructor(
        @Inject('NotificationHandler') protected readonly notificationService: NotificationHandlerService,
        protected httpOptionsService: HttpOptionsService,
        @Inject('NotificationMessages') protected notificationMessages: NotificationMessagesService,
    ) {}
    
    protected addNotification(text: string, type: string = 'error'): void {
        this.notificationService.addMessage({
            type: type,
            text: text
        })
    }
}
