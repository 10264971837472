import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthService} from './services';
import {AuthGuard, GuestGuard} from './guards';
import {Configuration} from '../http-cache/constants';
import {ConfigsContract} from './interfaces/configs.interface';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [

    ]
})
export class AuthModule {
    static forRoot(config: ConfigsContract) {
        return {
            ngModule: AuthModule,
            providers: [
                AuthService,
                AuthGuard,
                GuestGuard,
                {provide: Configuration, useValue: config},
            ]
        }
    }
}
