import { Injectable } from '@angular/core';
import {OptionsServiceContract} from '../interfaces';
import {CacheParams} from '../classes';

@Injectable()
export class CacheOptionService implements OptionsServiceContract {

  private params: CacheParams;
  constructor() { }
  
  public getCacheParams(): CacheParams {
    return this.params || new CacheParams({});
  }
  
  public getFullCacheKey(url: string): string[] {
    return this.getCacheParams().getFullCacheKey(url);
  }
  
  public shouldRemoveCache(): boolean {
    return !!this.getCacheParams().removingCacheKey;
  }
  
  public getCacheKey(): string {
    return this.shouldCacheRequest() ? this.params.cacheKey : '';
  }
  
  public getRemovingCacheKey(): string {
    return this.params ? this.params.removingCacheKey : '';
  }
  
  public shouldCacheRequest(): boolean {
    return this.params && this.params.shouldCache;
  }
  
  public resetCacheParams(): void {
    delete this.params;
  }
  
  public setCacheParams(data: any): void {
    this.params = new CacheParams(data);
  }
}
