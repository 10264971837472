import {ConfigsContract} from '../../interfaces';
import {StorageContract} from '../../interfaces';
import {StoreData} from '../../interfaces';
import {StoreItem} from '../../interfaces';

export abstract class BaseStorage implements StorageContract {
    configs: ConfigsContract;
    
    protected storeData: StoreData = {};
    
    constructor(configs: ConfigsContract) {
        this.configs = configs;
    }
    
    public has(key: string[]): boolean {
        let data: StoreData | StoreItem = this.storeData;
        for(let i in key) {
            if(!data[key[i]]) {
                return false;
            }
            
            data = data[key[i]];
        }
        
        return this.validateCache(<StoreItem>data);
    
    }
    
    public get(key: string[]): any {
        let data: StoreData | StoreItem = this.storeData;
    
        for(let i in key) {
            if(!data[key[i]]) {
                return null;
            }
            data = data[key[i]];
        }
    
        return (<StoreItem>data).data;
    }
    
    public set(key: string[], data: any): void {
        if(!this.storeData[key[0]]) {
            this.storeData[key[0]] = {}
        }
        
        this.storeData[key[0]][key[1]] = data;
    }
    
    public remove(key: string[]): void {
        delete this.storeData[key[0]];
    }
    
    private validateCache(data: StoreItem): boolean {
        if(!data.expireDate){
            return true;
        }
        
        return (new Date(data.expireDate)).getTime() > (new Date()).getTime();
    }
}
