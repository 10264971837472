import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpModule} from '../main/modules/http/http.module';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpCacheModule} from '../main/modules/http-cache';
import {StorageTypes} from '../main/modules/http-cache/constants';
import {AuthGuard, AuthModule, AuthService, GuestGuard} from '../main/modules/auth';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MetadataService} from "./services/metadata.service";
import {MatNativeDateModule, MatSnackBarModule} from "@angular/material";
import {NotificationHandlerService} from "../main/modules/http/services";
import {NotificationMessagesService} from "../main/modules/http/services/notification-messages.service";

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpModule.forRoot(),
        HttpCacheModule.forRoot({type: StorageTypes.memoryStorage}),
        AppRoutingModule,
        MatSnackBarModule,
        MatNativeDateModule,
        AuthModule.forRoot({
            loginUrl: '/auth/login',
            dashboardUrl: '/',
        }),
    ],
    providers: [
        MetadataService,
        {provide: 'NotificationHandler', useClass: NotificationHandlerService},
        {provide: 'NotificationMessages', useClass: NotificationMessagesService},
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
