import {BaseModel} from '../modules/http/classes/base.model';
import {environment} from "../../../environments/environment";
import {UserRoles} from "../../app/shared/enums/user-roles.enum";

export class User extends BaseModel {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    profilePicture: string;
    apiToken: string;
    role: UserRoles;

    constructor(data: any) {
        super();
        this.id = data.id;
        this.email = data.email;
        this.firstName = data.first_name;
        this.lastName = data.last_name;
        this.name = `${this.firstName} ${this.lastName}`;
        this.profilePicture = data.profile_picture;
        if (!this.profilePicture) {
            this.profilePicture = `${environment.baseUrl}/assets/images/default_profile_pic.jpg`
        }
        this.apiToken = data.api_token;
        this.role = data.role;
    }
}
