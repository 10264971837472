import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {
    ErrorHandlerService,
    HandlerService,
    HttpOptionsService,
    HttpService,
    NotificationHandlerService,
    SuccessResponseHandlerService
} from './services';
import {ErrorHandlerInterceptor, TransformerInterceptor} from './interceptors';
import {NotificationMessagesService} from './services/notification-messages.service';
import {CacheOptionService} from '../http-cache/services/cache-option.service';
import {HttpOptionSharerService} from './services/http-option-sharer.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [],
})
export class HttpModule {
    static forRoot() {
        return {
            ngModule: HttpModule,
            providers: [
                HttpService,
                SuccessResponseHandlerService,
                ErrorHandlerService,
                HttpOptionsService,
                NotificationHandlerService,
                HandlerService,
                {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
                {provide: HTTP_INTERCEPTORS, useClass: TransformerInterceptor, multi: true},
                {provide: 'NotificationHandler', useClass: NotificationHandlerService},
                {provide: 'NotificationMessages', useClass: NotificationMessagesService},
                {provide: 'HttpOptionSharers', useClass: HttpOptionSharerService, multi: true},
            ]
        }
    }
}
