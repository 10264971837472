import { Injectable } from '@angular/core';
import {CacheOptionService} from './cache-option.service';
import {HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {StoreService} from './store.service';
import {CacheParams} from '../classes';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  
  constructor(
      private optionsService: CacheOptionService,
      private store: StoreService
  ) {}
  
  
  public handleCacheProcess(req: HttpRequest<any>, next: HttpHandler) {
    if(this.optionsService.shouldCacheRequest() && this.store.has(this.optionsService.getFullCacheKey(req.urlWithParams))) {
      return this.getResponseFromCache(this.store.get(this.optionsService.getFullCacheKey(req.urlWithParams)));
    }
    
    if(this.optionsService.shouldRemoveCache()) {
        this.store.remove(this.optionsService.getRemovingCacheKey());
    }
   
    return this.sendRequest(req, next);
  }
  
  public getResponseFromCache(cachedResponse: any): Observable<HttpResponse<any>> {
    return of(cachedResponse)
        .pipe(
            map(val => new HttpResponse({
              body: val.body,
              headers: val.headers,
              status: val.status,
              statusText: val.statusText,
              url: val.url,
            }))
        );
  }
  
  public resetCacheParams(): void {
    this.optionsService.resetCacheParams();
  }
  
  public sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let cacheParams: CacheParams = this.optionsService.getCacheParams();
    return next.handle(req)
      .pipe(
        map(event => {
          if (cacheParams.shouldCache && event instanceof HttpResponse) {
            this.store.set(cacheParams.getFullCacheKey(req.urlWithParams), event, cacheParams.expireTime);
          }
          return event;
        })
      );
  }
  
}
