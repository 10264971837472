import {OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {fromAMDToUSD, fromUSDToAMD} from "../app/utils/constants/conversation-rates";

export class BaseComponent implements OnDestroy {
    public subscriptions: Subscription[] = [];
    public fromUSDtoAMD = fromUSDToAMD;
    public fromAMDtoUSD = fromAMDToUSD;
    ngOnDestroy() {
        this.subscriptions.forEach((sub: Subscription) => {
            if(sub) {
                sub.unsubscribe();
            }
        })
    }
}
