import { Injectable } from '@angular/core';
import {HandlerService} from './handler.service';

@Injectable({
  providedIn: 'root'
})
export class SuccessResponseHandlerService extends HandlerService {
    public handle(res: any): void {         // @TODO write interface
        if(this.httpOptionsService.handleSuccessResponse) {
            this.addNotification(res[0], 'success');  // @TODO
        }
    }
}
