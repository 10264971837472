import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {BaseAuthGuard} from './base-auth.guard';

@Injectable()
export class GuestGuard extends BaseAuthGuard {
 
  protected checkAuthFail(): Observable<boolean> {
    return of(true);
  }
  
  protected checkSuccessResponse(): boolean {
    this.router.navigate([this.configuration.dashboardUrl]);
    return false;
  }
}
