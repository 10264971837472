import {Injectable} from '@angular/core';
import {CacheOptionService} from './cache-option.service';

@Injectable({
  providedIn: 'root'
})
export class HttpOptionSharerService {
  constructor(
      private optionsService: CacheOptionService,
  ) { }
  
  public setOptions(data: any): void {
    if(data.cacheKey !== undefined || data.removingCacheKey !== undefined) {
      this.optionsService.setCacheParams(data);
    }
  }
}
