import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {HttpOptionsService} from '../services';
import {ErrorHandlerService} from '../services';
import {HandledError} from '../classes/handled-error';
import {Observable} from 'rxjs';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        private httpOptions: HttpOptionsService,
        private errorHandler: ErrorHandlerService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HandledError>> {
        return next.handle(request)
            .pipe(
                catchError(this.handleError.bind(this))
            );
    }

    private handleError(error: HttpErrorResponse): Observable<HandledError> {
        return this.errorHandler.handle(error);
    }
}
