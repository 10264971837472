import {StorageContract} from '../../interfaces/storage.interface';
import {BaseStorage} from './base.storage';
import {ConfigsContract} from '../../interfaces/configs.interface';
import {from, fromEvent} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

export class LocalStorage extends BaseStorage implements StorageContract {
 
    constructor(configs: ConfigsContract) {
        super(configs);
        this.checkStorageData();
        this.subscribeToStorageChanges();
    }
    
    public set(key: string[], data: any): void {
        super.set(key, data);
        this.pushStorageData();
    }
    
    public remove(key: string[]): void {
        super.remove(key);
        this.pushStorageData();
    }
    
    private checkStorageData(): void{
        if(!this.pullStorageData()) {
            this.pushStorageData();
        }
    }
    
    private pushStorageData(): void {
        if(!this.storeData) {
            this.storeData = {};
        }
        
        localStorage.setItem(this.configs.storageKey, JSON.stringify(this.storeData));
    }
    
    private subscribeToStorageChanges(): void {
        fromEvent(window, 'storage')
            .pipe(
                filter((event: StorageEvent) => event.storageArea == localStorage),
                filter((event: StorageEvent) => event.key == this.configs.storageKey),
                tap( _ => this.pullStorageData())
            ).subscribe();
    }
    
    private pullStorageData(): boolean {
        try {
            this.storeData = JSON.parse(localStorage.getItem(this.configs.storageKey));
        } catch (e) {
            this.storeData = {};
            this.pushStorageData();

            return false;
        }
        
        return !!this.storeData;
    }
}
