import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Inject} from '@angular/core';
import {Configuration} from '../../http-cache/constants';
import {ConfigsContract} from '../interfaces/configs.interface';
import {AuthService} from '../services';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export abstract class BaseAuthGuard implements CanActivate {
    constructor(
        @Inject(Configuration) protected configuration: ConfigsContract,
        protected authService: AuthService,
        protected router: Router
    ) {}
    
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.checkLoginState()
            .pipe(
                map(_ => this.checkSuccessResponse()),
                catchError(_ => this.checkAuthFail()),
            );
    }
    
    protected abstract checkAuthFail();
    
    protected abstract checkSuccessResponse();
}
