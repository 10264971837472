import { Injectable } from '@angular/core';
import {Notification} from '../interfaces/notification.interface';
import {NotificationHandlerContract} from '../interfaces';
import {MatSnackBar} from "@angular/material";

@Injectable()
export class NotificationHandlerService implements NotificationHandlerContract {

    constructor(
        private snackBar: MatSnackBar
    ) {
    }
    
    public addMessage(message: Notification): void {
        this.snackBar.open(message.text, null, {
            duration: 2000,
        });
    }
}
