import {Inject, Injectable} from '@angular/core';
import {StorageContract} from '../interfaces';
import {StorageFactory} from '../classes/storage-drivers';
import {Configuration} from '../constants';
import {isArray} from 'util';
import {getPreparedConfigs} from '../helpers';

@Injectable()
export class StoreService {
  private storageDriver: StorageContract;
  
  constructor(
      @Inject(Configuration) private configuration: any,
  ) {
    this.setDriver();
  }
  
  public has(key: string[] | string): boolean {
    return this.storageDriver.has(this.getPreparedKey(key));
  }
  
  public get(key: string[] | string): any {
    return this.storageDriver.get(this.getPreparedKey(key));
  }
  
  public set(key: string[] | string, data: any, expireTime: number): void {
    this.storageDriver.set(this.getPreparedKey(key), {data: data, expireDate: this.getExpireDate(expireTime)});
  }
  
  public remove(key: string[] | string): void {
    this.storageDriver.remove(this.getPreparedKey(key));
  }
  
  private setDriver(): void {
    this.storageDriver = StorageFactory.getStorageByType(getPreparedConfigs(this.configuration));
  }
  
  private getPreparedKey(key): string[] {
    return isArray(key) ? key : [key];
  }
  
  private getExpireDate(expireTime: number) {
    if(!expireTime && expireTime !== 0) {
      return null;
  
    }
    
    let date = new Date();
    date.setMinutes(date.getMinutes() + expireTime);
    
    return date;
  }
}
